<template>
  <div>
    <!-- 采购经销商排行分析-->
    <filter-view :loading="loading" @submit="loadEchart">
      <retail-Filter :show-opt="showOpt" :query="query" :rank="rank" @init="loadEchart" />
    </filter-view>
    <div class="result">
      <div class="padding-10 fs-small fc-g">分析结果</div>
      <div class="round-panel bc-w">
        <div v-show="store && store.length">
          <div class="c h sb">
            <div class="no-flex padding-10"></div>
            <el-radio-group v-model="chartTypes" size="mini" @change="draw">
              <el-radio label="zzt">柱状图</el-radio>
              <el-radio label="tzt">饼图</el-radio>
            </el-radio-group>
          </div>
          <div class="sep-t" ref="chart" style="height:400px;"></div>
        </div>
        <div class="fc-g ta-c padding-10" v-if="!store || !store.length">没有分析数据，请更改统计分析条件或稍候重试。</div>
      </div>
      <template v-if="store && store.length > 0">
        <div class="padding-10 fs-small fc-g">数据列表</div>
        <div class="round-panel bc-w">
          <div v-for="o in pagedStore" :key="o.rank" class="padding-05 sep-b">
            <div class="fs-large bold padding-05">排名{{ o.rank }}，{{ o.name }}</div>
            <div class="h c wrap fs-small lh-150">
              <div class="padding-05" style="width: 50%">
                销售金额：{{ $price(o.realSumMoney) }}
                <br />
                惠前金额：{{ $price(o.sumMoney) }}
                <br />
                同比金额：{{ $price(o.compareRealSumMoney) }}
                <br />
                同比增长率：{{ $percent(o.realSumMoneyRatio) }}
                <br />
                环比金额：{{ $price(o.mrealSumMoney) }}
                <br />
                环比增长率{{ $percent(o.mrealSumMoneyRatio) }}
              </div>
              <div class="padding-05" style="width: 50%">
                发货金额：{{ $price(o.sendMoney) }}
                <br />
                惠前金额：{{ $price(o.sendMoney) }}
                <br />
                同比金额：{{ $price(o.compareRealSendMoney) }}
                <br />
                同比增长率：{{ $percent(o.realSendMoneyRatio) }}
                <br />
                环比金额：{{ $price(o.mrealSendMoney) }}
                <br />
                环比增长率{{ $percent(o.mrealSendMoneyRatio) }}
              </div>
              <div class="padding-05" style="width: 50%">
                销售数量：{{ o.saleNumber || "-" }}
                <br />
                同比数量：{{ o.compareSaleNumber || "-" }}
                <br />
                同比增长率：{{ $percent(o.saleNumberRatio) }}
                <br />
                环比数量：{{ o.msaleNumber || "-" }}
                <br />
                环比增长率{{ $percent(o.msaleNumberRatio) }}
              </div>
              <div class="padding-05" style="width: 50%">
                发货数量：{{ o.sendNumber || "-" }}
                <br />
                同比数量：{{ o.compareSendNumber || "-" }}
                <br />
                同比增长率：{{ $percent(o.sendNumberRatio) }}
                <br />
                环比数量：{{ o.msendNumber || "-" }}
                <br />
                环比增长率{{ $percent(o.msendNumberRatio) }}
              </div>
            </div>
          </div>
          <div class="ta-c">
            <el-pagination class="h5-pagination" :total="totalElements" :current-page.sync="page" :page-size="size" layout="prev, pager, next" hide-on-single-page />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import filterView from "../assembly/filter";
import retailFilter from "../assembly/filter/distributors";
import request from "@/utils/request";
export default {
  components: {
    filterView,
    retailFilter,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      page: 1,
      size: 6,
      totalElements: null,
      chartTypes: "zzt",
      store: [],
      chart: null,
      showOpt: {
        showDimension: false, //分析维度隐藏
        showYearonyear: true, //同比显示
        showCommodity: true, //指定商品
        showRangGoods: true, //商品范围
        showMonth: true, //环比隐藏
        showRank: true, //显示排名对象
        showRankGist: true, //显示排名依据
        showCompareyear: false,
        showLevel: false, //汇总级别
        showCategory: false, //对比类型
        showReported: false, //只统计已上报
        showTimeRange: true, //时间范围
      },
      query: {
        addressType: "area",
        treeNodeId: null,
        treeNodeType: null,
        dateGroup: "day",
        StandardAreaGroup: null,
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        purchaseValueGroup: "realSumMoney", //排名依据
        AreaGroupType: "saleValueGroup",
        yearOnYearShow: false,
        compareYear: new Date(
          now.getFullYear() - 1,
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        compareMonth: false,
        goods: null,
        brandId: null,
        seriesId: null,
        categoryId: null,
        page: 0,
        size: 10000,
      },
      rank: {
        rankDefines: [
          {
            name: "区域",
            value: { AreaGroupType: "area", shopAreaGroup: "area" },
          },
          {
            name: "经销商",
            value: { AreaGroupType: "area", shopAreaGroup: "distributor" },
          },
          {
            name: "省",
            value: { AreaGroupType: "standard", cityAreaGroup: "province" },
          },
          {
            name: "市",
            value: { AreaGroupType: "standard", cityAreaGroup: "city" },
          },
        ],
        multParams: 0,
      },
    };
  },
  watch: {
    store() {
      this.page = 1;
    },
  },
  computed: {
    pagedStore() {
      let start = (this.page - 1) * this.size,
        len = this.size;
      if (start + len > this.store.length) {
        len = this.store.length - start;
      }
      return this.store.slice(start, start + len);
    },
  },
  methods: {
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      Object.assign(data, this.rank.rankDefines[this.rank.multParams].value);
      let sd = new Date(data.compareYear);
      data.begDate = new Date(data.begDate);
      data.endDate = new Date(data.endDate);
      data.begDate = new Date(
        data.begDate.getFullYear(),
        data.begDate.getMonth(),
        data.begDate.getDate()
      ).getTime();
      data.endDate = new Date(
        data.endDate.getFullYear(),
        data.endDate.getMonth(),
        data.endDate.getDate() + 1
      ).getTime();
      if (data.addressType == "standard") {
        if (this.query.StandardAreaGroup == 0) {
          data.StandardAreaGroup = "country";
        }
        if (this.query.StandardAreaGroup == 1) {
          data.StandardAreaGroup = "province";
        }
        if (this.query.StandardAreaGroup == 2) {
          data.StandardAreaGroup = "city";
        }
        if (this.query.StandardAreaGroup == 3) {
          data.StandardAreaGroup = "district";
        }
        delete data.treeNodeType;
      } else {
        data.treeNodeType = this.query.treeNodeType;
        delete data.StandardAreaGroup;
      }
      if (data.goods && data.goods.length)
        data.skuIds = data.goods.map((o) => o.id);
      delete data.goods;
      if (this.query.yearOnYearShow == false) {
        delete data.compareYear;
      } else {
        data.compareYear = sd.getFullYear();
      }
      return data;
    },
    loadEchart() {
      let data = Object.assign({}, this.makeParams());
      this.loading = true;
      if (this.query.yearOnYearShow == false) {
        request({
          url: "@host:analysis;api/analy/purchase/sale/rank",
          method: "get",
          params: data,
        })
          .then((res) => {
            this.store = res.content;
            this.totalElements = res.totalElements;
            this.$nextTick((_) => {
              this.draw();
            });
          })
          .catch((err) => {
            this.store = [];
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.query.yearOnYearShow == true) {
        request({
          url: "@host:analysis;api/analy/purchase/sale/rank",
          method: "get",
          params: data,
        })
          .then((res) => {
            this.store = res.content;
            this.totalElements = res.totalElements;
            this.$nextTick((_) => {
              this.draw();
            });
          })
          .catch((err) => {
            this.store = [];
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    draw() {
      if (this.store && this.store.length) {
        let sourceName = [];
        let sourceData1 = []; //系列1数据
        let sourceData2 = []; //系列2数据
        let sourceData3 = []; //系列3数据
        let binChart = [];
        let binChart2 = [];
        let binChart3 = [];
        let svgp = [];
        let groupRankName = [];
        let legendsvgp = [];
        let yAxisName = "";
        (this.store || []).forEach((o) => {
          sourceName.push(o.name || "不详");
          if (
            this.query.purchaseValueGroup == "saleNumber" ||
            this.query.purchaseValueGroup == "sendNumber"
          ) {
            sourceData1.push(o[this.query.purchaseValueGroup]);
            yAxisName = "数量";
          } else {
            sourceData1.push(
              Math.round(o[this.query.purchaseValueGroup] * 0.000001 * 100) /
                100
            );
            yAxisName = "金额（万元）";
          }
        });
        switch (this.query.purchaseValueGroup) {
          case "realSumMoney":
            legendsvgp.push("销售金额");
            svgp.push("销售金额");
            break;
          case "saleNumber":
            legendsvgp.push("单品数量");
            svgp.push("单品数量");
            break;
          case "sendMoney":
            legendsvgp.push("发货金额");
            svgp.push("发货金额");
            break;
          case "sendNumber":
            legendsvgp.push("发货数量");
            svgp.push("发货数量");
            break;
        }

        switch (this.query.AreaGroupType) {
          case "saleValueGroup":
            groupRankName.push("区域排名");
            break;
          case "shopAreaGroup":
            groupRankName.push("店名排名");
            break;
        }
        if (this.query.yearOnYearShow == true) {
          legendsvgp.push("同比");
          (this.store || []).forEach((o) => {
            if (o) {
              //  compareRealSumMoney  compareRealSendMoney  compareSaleNumber  compareSendNumber
              switch (this.query.purchaseValueGroup) {
                case "saleNumber":
                  sourceData2.push(o.compareSaleNumber);
                  binChart2.push({
                    name: o.name || "不详",
                    value: o.compareSaleNumber,
                  });
                  yAxisName = "数量";
                  break;
                case "sendNumber":
                  sourceData2.push(o.compareSendNumber);
                  binChart2.push({
                    name: o.name || "不详",
                    value: o.compareSendNumber,
                  });
                  yAxisName = "数量";
                  break;

                case "realSumMoney":
                  sourceData2.push(
                    Math.round(o.compareRealSumMoney * 0.000001 * 100) / 100
                  );
                  binChart2.push({
                    name: o.name || "不详",
                    value:
                      Math.round(o.compareRealSumMoney * 0.000001 * 100) / 100,
                  });
                  yAxisName = "额（万元）";
                  break;
                case "realSendMoney":
                  sourceData2.push(
                    Math.round(o.compareRealSendMoney * 0.000001 * 100) / 100
                  );
                  binChart2.push({
                    name: o.name || "不详",
                    value:
                      Math.round(o.compareRealSendMoney * 0.000001 * 100) / 100,
                  });
                  yAxisName = "额（万元）";
                  break;
              }
            }
          });
        }
        if (this.query.compareMonth == true) {
          legendsvgp.push("环比");
          (this.store || []).forEach((o) => {
            if (o) {
              // mrealSendMoney mrealSumMoney msaleNumber msendNumber
              switch (this.query.purchaseValueGroup) {
                case "saleNumber":
                  sourceData3.push(o.msaleNumber);
                  binChart3.push({
                    name: o.name || "不详",
                    value: o.msaleNumber,
                  });
                  yAxisName = "数量";
                  break;
                case "sendNumber":
                  sourceData3.push(o.msendNumber);
                  binChart3.push({
                    name: o.name || "不详",
                    value: o.msendNumber,
                  });
                  yAxisName = "数量";
                  break;
                case "realSumMoney":
                  sourceData3.push(
                    Math.round(o.mrealSumMoney * 0.000001 * 100) / 100
                  );
                  binChart3.push({
                    name: o.name || "不详",
                    value: Math.round(o.mrealSumMoney * 0.01 * 100) / 100,
                  });
                  yAxisName = "额（万元）";
                  break;
                case "realSendMoney":
                  sourceData3.push(
                    Math.round(o.mrealSendMoney * 0.000001 * 100) / 100
                  );
                  binChart3.push({
                    name: o.name || "不详",
                    value: Math.round(o.mrealSendMoney * 0.01 * 100) / 100,
                  });
                  yAxisName = "额（万元）";
                  break;
              }
            }
          });
        }

        if (!this.chart) {
          this.chart = echarts.init(this.$refs.chart, "light");
        }
        this.chart.clear();
        binChart = (this.store || []).map((o) => {
          if (
            this.query.purchaseValueGroup == "saleNumber" ||
            this.query.purchaseValueGroup == "sendNumber"
          ) {
            return {
              name: o.name || "不详",
              value: o[this.query.purchaseValueGroup],
            };
          } else {
            return {
              name: o.name || "不详",
              value: o[this.query.purchaseValueGroup].toFixed(2) / 100,
            };
          }
        });
        if (this.chartTypes == "zzt") {
          this.chart.setOption({
            tooltip: {
              trigger: "axis",
              confine: true,
            },
            legend: {
              data: legendsvgp,
            },
            calculable: true,
            grid: {
              left: 50,
              right: 20,
              bottom: 50,
              top: 50,
            },
            xAxis: [
              {
                type: "category",
                data: sourceName,
                axisLabel: {
                  formatter: function (value) {
                    if (value.length > 5) {
                      return value.substr(0, 5) + "…";
                    }
                    return value;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                name: yAxisName,
              },
            ],
            series: [
              {
                name: svgp,
                type: "bar",
                data: sourceData1,
                itemStyle: {
                  normal: {
                    label: {
                      show: true,
                      position: "top",
                    },
                  },
                },
              },
              sourceData2.length > 0
                ? {
                    name: "同比",
                    type: "bar",
                    data: sourceData2,
                    itemStyle: {
                      normal: {
                        label: {
                          show: true,
                          position: "top",
                        },
                      },
                    },
                  }
                : "",
              sourceData3.length > 0
                ? {
                    name: "环比",
                    type: "bar",
                    data: sourceData3,
                    itemStyle: {
                      normal: {
                        label: {
                          show: true,
                          position: "top",
                        },
                      },
                    },
                  }
                : "",
            ],
            dataZoom: {
              show: sourceName.length > 5, // 为true 滚动条出现
              filterMode: "weakFilter",
              type: "slider", // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
              height: 15,
              start: 0,
              end: (5 / sourceName.length) * 100,
              bottom: 10,
              handleIcon:
                "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
              handleSize: "80%",
              zoomLock: true,
              showDetail: false,
            },
          });
        } else if (this.chartTypes == "tzt") {
          if (!this.chart) {
            this.chart = echarts.init(this.$refs.chart, "light");
          }
          this.chart.clear();
          this.chart.setOption({
            title: {
              left: "center",
              top: 20,
            },
            tooltip: {
              trigger: "item",
              confine: true,
              formatter: "{a} <br/>{b} : {c} ({d}%)",
            },
            series: [
              {
                name: svgp + groupRankName,
                type: "pie",
                radius: "30%",
                center: ["50%", "18%"],
                data: binChart.sort(function (a, b) {
                  return a.value - b.value;
                }),
                label: {
                  formatter: function (p) {
                    if (p.name.length > 5) return p.name.substr(0, 5) + "…";
                    else return p.name;
                  },
                },
              },
              {
                name: svgp + groupRankName + "同比分析",
                type: "pie",
                radius: "30%",
                center: ["50%", "50%"],
                data: binChart2.sort(function (a, b) {
                  return a.value - b.value;
                }),
                label: {
                  formatter: function (p) {
                    if (p.name.length > 5) return p.name.substr(0, 5) + "…";
                    else return p.name;
                  },
                },
              },
              {
                name: svgp + groupRankName + "环比分析",
                type: "pie",
                radius: "30%",
                center: ["50%", "80%"],
                data: binChart3.sort(function (a, b) {
                  return a.value - b.value;
                }),
                label: {
                  formatter: function (p) {
                    if (p.name.length > 5) return p.name.substr(0, 5) + "…";
                    else return p.name;
                  },
                },
              },
            ],
          });
        }
      } else {
        this.chart = null;
      }
    },
  },
};
</script>

<style>
</style>